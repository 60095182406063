<i18n src="@/common/locales.json"></i18n>
<template>
  <div class="home" style="max-width: 500px; margin: auto;">
    <div
      v-if="webId !== null && profile.name !== ''"
      class="name"
      style="text-align: left;width: 90%;margin: 10px auto;font-size: 20px;font-weight:700;box-sizing: border-box;letter-spacing: 0.2px; line-height: 1.3;color: #333;"
    >
      <span>안녕하세요 {{ profile.name }}님,</span>
    </div>
    <div
      v-else
      style="text-align: left;width: 90%;margin: 10px auto;font-size: 20px;font-weight:700;box-sizing: border-box;letter-spacing: 0.2px; line-height: 1.3;color:#333"
    >
      <span>안녕하세요,</span>
    </div>
    <div class="carousel">
      <b-carousel
        id="carousel-banner"
        fade
        v-model="slide"
        :interval="5000"
        background="#fff"
        img-width="1024"
        img-height="480"
        style="width: 90%;margin: 0 auto 30px;background-color: #fff;border-radius: 1rem;box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);overflow: hidden;"
      >
        <a href="/about">
          <b-carousel-slide
            img-src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/banner/carousel_slide1.png"
          ></b-carousel-slide>
        </a>
        <a href="/editor">
          <b-carousel-slide
            img-src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/banner/carousel_slide2.png"
          ></b-carousel-slide>
        </a>
      </b-carousel>
    </div>
    <!-- <b-card
      class="text-center"
      style="width: 90%;margin: 20px auto 30px;height: 19.7vh;margin-bottom: 30px;"
    >
      <div style="width:100%;padding: 5px 0;">
        <p style="height: 50px; overflow: hidden;">
          <img height="100%" alt="Vue logo" src="../assets/candiylogo.png" />
        </p>
        <p></p>
        <h1 style="font-size:32px; font-weight:600">
          CANDiY
        </h1>
        <h4 style="margin-top:-6px;" class="h5 text-muted">
          {{ $t("You Can DIY Your Data") }}
        </h4>
      </div>
    </b-card> -->

    <HelloWorld />

    <!--    <div>Due to migration from solid.community to solidcommunity.net, you may want to use <a href="/migration">Migration</a>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    HelloWorld: () => import("@/components/layout/HelloWorld"),
  },
  computed: {
    profile() {
      return this.$store.state.solid.profile;
    },
    webId() {
      return this.$store.state.solid.webId;
    },
  },
};
</script>
<style>
.home {
}
</style>
